/*
//margin-top
@margin-top-smaller: 1rem;
@margin-top-small: 2rem;
@margin-top-medium: 4rem;
@margin-top-big: 6rem;
@margin-top-large: 8rem;
@margin-top-larger: 10rem;

//margin-bottom
@margin-bottom-smaller: 1rem;
@margin-bottom-small: 2rem;
@margin-bottom-medium: 4rem;
@margin-bottom-big: 6rem;
@margin-bottom-large: 8rem;
@margin-bottom-larger: 10rem;
*/
.dummy-div-to-the-top {
  position: relative;
  display: none;
  width: 100%;
  height: 4.5rem;
}
.about-container {
  max-width: 90rem;
  margin: 6rem auto 6rem auto;
  text-align: justify;
}
.title-of-content {
  font-size: 2.4rem;
  margin-bottom: 2rem;
}
.text-of-content {
  font-size: 1.8rem;
  line-height: 2;
}
@media (max-width: 56.25em) {
  .dummy-div-to-the-top {
    display: block;
  }
  .about-container {
    width: 80%;
  }
}
@media (max-width: 28.125em) {
  .dummy-div-to-the-top {
    height: 4.5rem;
  }
}
