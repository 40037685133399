/*
@margin-top-smaller: 1rem;
@margin-top-small: 2rem;
@margin-top-medium: 4rem;
@margin-top-big: 6rem;
@margin-top-large: 8rem;
@margin-top-larger: 10rem;

//margin-bottom
@margin-bottom-smaller: 1rem;
@margin-bottom-small: 2rem;
@margin-bottom-medium: 4rem;
@margin-bottom-big: 6rem;
@margin-bottom-large: 8rem;
@margin-bottom-larger: 10rem;
*/
.footer {
  display: grid;
  grid-template-columns: 1fr repeat(3, minmax(max-content, 1fr)) 1fr;
  grid-template-rows: repeat(6, max-content);
  justify-content: center;
  align-content: center;
  background-color: #f2f2f2;
}
.brand-section-f {
  grid-row: 1 / span 2;
  grid-column: 3 / span 1;
  text-decoration: none;
  display: grid;
}
.brand-section-f__logo-f {
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
  justify-self: center;
  align-self: center;
  margin-top: 1.5rem;
  display: block;
  width: 5rem;
  height: auto;
}
.brand-section-f__title-f {
  grid-row: 2 / span 1;
  grid-column: 1 / span 1;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 1rem 0 1rem 0;
  color: rgba(0, 0, 0);
  justify-self: center;
  align-self: center;
  text-align: center;
  border-bottom: 1px solid #d6d6d6;
}
.footer-nav {
  grid-row: 3 / span 3;
  grid-column: 3 / span 1;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-template-rows: repeat(3, 2rem);
  grid-row-gap: 2.5rem;
  grid-column-gap: 3rem;
  margin: 2rem 0 2rem 0;
}
.footer-nav__list-item {
  list-style: none;
  font-size: 1.6rem;
}
.footer-nav__list-item--1 {
  text-align: right;
}
.footer-nav__list-item--2 {
  text-align: center;
}
.footer-nav__list-item--3 {
  text-align: left;
}
.footer-nav__list-item--4 {
  text-align: right;
}
.footer-nav__list-item--5 {
  text-align: center;
}
.footer-nav__list-item--6 {
  text-align: left;
}
.footer-nav__list-item--7 {
  text-align: right;
}
.footer-nav__list-item--8 {
  text-align: center;
}
.footer-nav__list-item--9 {
  text-align: left;
}
.no-textdecor {
  text-decoration: none;
  color: black;
}
.footer-date {
  grid-row: 6 / span 1;
  grid-column: 1 / span 6;
  width: 100%;
  text-align: center;
  background-color: #e3e3e3;
  color: #9d9d9d;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.5rem 0 1.5rem 0;
}
@media (max-width: 37.5em) {
  .footer {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(12, max-content);
  }
  .brand-section-f {
    grid-column: 1;
  }
  .footer-nav {
    grid-row: 3 / span 8;
    grid-column: 1 / span 1;
    grid-template-columns: 1fr;
    grid-template-rows: 9;
  }
  .footer-nav__list-item {
    list-style: none;
    font-size: 1.6rem;
  }
  .footer-nav__list-item--1 {
    text-align: center;
  }
  .footer-nav__list-item--3 {
    text-align: center;
    grid-row: 2 / span 1;
  }
  .footer-nav__list-item--4 {
    text-align: center;
  }
  .footer-nav__list-item--6 {
    text-align: center;
    grid-row: 5 / span 1;
  }
  .footer-nav__list-item--7 {
    text-align: center;
  }
  .footer-nav__list-item--9 {
    text-align: center;
  }
  .footer-date {
    grid-row: 12 / span 1;
    grid-column: 1 / span 1;
  }
}
@media (max-width: 28.125em) {
  .brand-section-f__title-f {
    width: 65%;
  }
}
