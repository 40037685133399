/*
//margin-top
@margin-top-smaller: 1rem;
@margin-top-small: 2rem;
@margin-top-medium: 4rem;
@margin-top-big: 6rem;
@margin-top-large: 8rem;
@margin-top-larger: 10rem;

//margin-bottom
@margin-bottom-smaller: 1rem;
@margin-bottom-small: 2rem;
@margin-bottom-medium: 4rem;
@margin-bottom-big: 6rem;
@margin-bottom-large: 8rem;
@margin-bottom-larger: 10rem;
*/
.navbar-fixed-props {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  font-family: "Arial";
}
.image-clickable-side {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 101;
}
.image-slider-button {
  position: fixed;
  top: 50%;
  background-color: transparent;
  font-family: 'Fredoka One';
  color: #fff;
  font-weight: 600;
  font-size: 3.8rem;
  cursor: pointer;
  border: solid white;
  border-width: 0 8px 8px 0;
  border-radius: 10%;
  display: inline-block;
  padding: 2.5rem;
}
@media only screen and (max-width: 28.125em) {
  .image-slider-button {
    top: 48%;
    padding: 1.8rem;
    border-width: 0 5px 5px 0;
  }
}
.pos-abs {
  position: absolute;
  top: 0;
  left: 0;
}
.bg-image {
  width: 100%;
  height: auto;
  display: block;
  background-size: cover;
}
.button-without-outline:focus {
  outline: none;
}
.button-push-animation:active {
  transform: translateY(2px);
}
.button-hover-animation:hover {
  opacity: 0.6;
}
.tabs-container {
  width: 100%;
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}
.property-section {
  grid-column: 1 / span 1;
  margin: 5rem 8rem 0 0;
  display: grid;
  grid-gap: 1.5rem;
}
.property-section--ph {
  grid-template-columns: minmax(8rem, 1fr) 1fr minmax(5rem, 1fr) repeat(3, 1fr);
  grid-template-rows: repeat(8, max-content);
}
.property-section--fur {
  margin: 5rem 8rem 0 0;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, max-content);
}
.property-section--ab {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, min-content);
}
.list-item {
  list-style: none;
  border-bottom: 1px solid #e7e7e7;
  display: grid;
}
.list-item:not(:first-child) {
  padding-top: 1.5rem;
}
.list-item--1-ph {
  grid-row: 1 / span 3;
  grid-column: 1 / span 6;
  grid-template-columns: minmax(8rem, 1fr) 1fr minmax(5rem, 1fr) repeat(3, 1fr);
}
.list-item--2-ph {
  grid-row: 4 / span 3;
  grid-column: 1 / span 6;
  grid-template-columns: minmax(8rem, 1fr) 1fr minmax(5rem, 1fr) repeat(3, 1fr);
}
.list-item--3-ph {
  grid-row: 7 / span 2;
  grid-column: 1 / span 6;
  grid-template-columns: minmax(8rem, 1fr) 1fr minmax(5rem, 1fr) repeat(3, 1fr);
}
.list-item--1-fur {
  grid-row: 1 / span 2;
  grid-column: 1 / span 6;
  grid-template-columns: repeat(5, 1fr);
}
.list-item--2-fur {
  grid-row: 3 / span 2;
  grid-column: 1 / span 2;
  grid-template-columns: minmax(5rem, 1fr) 1fr;
}
.list-item--3-fur {
  grid-row: 3 / span 2;
  grid-column: 3 / span 4;
  grid-template-columns: minmax(5rem, 1fr) 1fr;
  margin-left: 3rem;
}
.list-item--4-fur {
  grid-row: 5 / span 2;
  grid-column: 1 / span 6;
  grid-template-columns: minmax(8rem, 6fr) 1fr minmax(5rem, 1fr) repeat(3, 1fr);
}
.list-item--1-ab {
  grid-row: 1 / span 3;
  grid-column: 1 / span 10;
  grid-template-rows: repeat(3, min-content);
  grid-template-columns: repeat(10, 1fr);
}
.list-item--2-ab {
  grid-row: 4 / span 3;
  grid-column: 1 / span 10;
  grid-template-rows: repeat(3, min-content);
  grid-template-columns: repeat(10, 1fr);
}
.logo-and-title {
  border-bottom: 1px solid #e7e7e7;
  display: grid;
  grid-column: 1 / span 6;
  padding-bottom: 2rem;
  grid-template-columns: 5rem repeat(5, 1fr);
}
.logo-and-title--fur-left {
  grid-column: 1 / span 2;
  padding-bottom: 2rem;
  grid-template-columns: 5rem 1fr 1fr;
}
.logo-and-title--fur-right {
  grid-column: 1 / span 2;
  padding-bottom: 2rem;
  grid-template-columns: 5rem repeat(2, 1fr);
}
.logo-and-title--ab {
  grid-row: 1 / span 1;
  grid-column: 1 / span 10;
  grid-template-columns: 5rem repeat(9, 1fr);
}
.tabs-logo {
  grid-column: 1 / span 1;
  height: auto;
  width: 2.5rem;
}
.tabs-logo--weight {
  width: 3.2rem;
}
.tabs-logo--fur {
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
}
.tabs-title {
  grid-column: 2 / span 5;
  font-size: 2.4rem;
}
.tabs-title--fur {
  grid-row: 1 / span 1;
  grid-column: 2 / span 4;
}
.tabs-title--ab {
  grid-row: 1 / span 1;
  grid-column: 2 / span 9;
}
.property {
  font-size: 1.8rem;
  color: #9d9d9d;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
}
.property--1-ph {
  grid-column: 1 / span 1;
}
.property--2-ph {
  grid-column: 3 / span 3;
}
.property--1-ab {
  grid-column: 1 / span 2;
  font-weight: 600;
}
.property--2-ab {
  grid-column: 4 / span 1;
  font-weight: 600;
}
.property--2-ab-wider {
  grid-column: 4 / span 3;
  font-weight: 600;
}
.property--3-ab {
  grid-column: 6 / span 3;
  font-weight: 600;
}
.property--3-ab-smaller {
  grid-column: 8 / span 3;
  font-weight: 600;
}
.value {
  font-size: 2.4rem;
  color: #9d9d9d;
  padding-bottom: 1.5rem;
}
.value--1-ph {
  grid-column: 1 / span 1;
}
.value--2-ph {
  grid-column: 3 / span 3;
}
.value--last {
  grid-column: 1 / span 3;
  padding-top: 3rem;
}
.value--fur-left {
  padding-top: 1.5rem;
  grid-column: 1 / span 3;
}
.value--fur-right {
  grid-row: 2 / span 1;
  grid-column: 1 / span 3;
  padding-top: 1.5rem;
}
.value--wide {
  grid-column: 1 / span 6;
  padding-top: 1.5rem;
}
.value--1-ab {
  grid-column: 1 / span 2;
  font-size: 2rem;
}
.value--2-ab {
  grid-column: 4 / span 2;
  font-size: 2rem;
}
.value--2-ab-wider {
  grid-column: 4 / span 4;
  font-size: 2rem;
}
.value--3-ab {
  grid-column: 6 / span 5;
  font-size: 2rem;
}
.value--3-ab-smaller {
  grid-column: 8 / span 3;
  font-size: 2rem;
}
.image-section {
  grid-column: 2 / span 1;
  margin: 5rem 0;
}
.image-section .property-image {
  width: 100%;
  height: auto;
  display: block;
  background-size: cover;
}
@media only screen and (max-width: 75em) {
  .tabs-title {
    font-size: 2rem;
  }
  .property {
    font-size: 1.6rem;
  }
  .value {
    font-size: 2rem;
  }
  .list-item--2-fur {
    grid-row: 3 / span 2;
    grid-column: 1 / span 6;
    display: grid;
    grid-template-columns: minmax(5rem, 1fr) 1fr;
  }
  .list-item--3-fur {
    grid-row: 5 / span 2;
    grid-column: 1 / span 6;
    display: grid;
    grid-template-columns: minmax(5rem, 1fr) 1fr;
    margin-left: 0;
  }
  .list-item--4-fur {
    grid-row: 7 / span 2;
  }
  .list-item--1-ab {
    grid-template-rows: repeat(4, min-content);
    grid-template-columns: repeat(10, 1fr);
  }
  .list-item--2-ab {
    grid-template-rows: repeat(4, min-content);
    grid-template-columns: repeat(10, 1fr);
  }
  .logo-and-title--ab {
    grid-row: 1 / span 1;
    grid-column: 1 / span 10;
    grid-template-columns: 5rem repeat(9, 1fr);
  }
  .value--1-ab {
    grid-column: 1 / span 3;
    font-size: 2rem;
  }
  .value--2-ab {
    grid-column: 6 / span 10;
  }
  .value--2-ab-wider {
    grid-row: 5 / span 1;
    grid-column: 1 / span 10;
    font-size: 2rem;
  }
  .value--3-ab {
    grid-row: 5 / span 1;
    grid-column: 1 / span 10;
    font-size: 2rem;
  }
  .value--3-ab-smaller {
    grid-column: 6 / span 10;
  }
  .property--2-ab {
    grid-column: 6 / span 10;
  }
  .property--3-ab-smaller {
    grid-column: 6 / span 10;
  }
  .property--2-ab-wider {
    padding-top: 0;
    grid-row: 4 / span 1;
    grid-column: 1 / span 10;
  }
  .property--3-ab {
    grid-row: 4 / span 1;
    grid-column: 1 / span 10;
  }
}
@media only screen and (max-width: 56.25em) {
  .tabs-container {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
  }
  .property-section {
    margin: 5rem 0 5rem 0;
  }
  .image-section {
    grid-column: 1 / span 1;
    margin: 1rem 0 3rem 0;
  }
}
@media only screen and (max-width: 37.5em) {
  .property-section {
    margin: 4rem 0;
  }
  .logo-and-title {
    padding-bottom: 1.5rem;
  }
  .tabs-logo {
    width: 2rem;
  }
  .tabs-logo--weight {
    width: 2.7rem;
  }
  .tabs-title {
    font-size: 2rem;
  }
  .property {
    padding-top: 1.5rem;
  }
  .value {
    font-size: 2rem;
    padding-bottom: 1rem;
  }
  .value--last {
    padding-top: 2rem;
  }
  .image-section {
    margin: 0;
  }
}
@media only screen and (max-width: 37.5em) {
  .list-item--1-ab {
    grid-template-rows: repeat(6, min-content);
    grid-template-columns: repeat(10, 1fr);
  }
  .list-item--2-ab {
    grid-template-rows: repeat(6, min-content);
    grid-template-columns: repeat(10, 1fr);
  }
  .property--2-ab {
    grid-row: 6 / span 1;
    grid-column: 1 / span 10;
  }
  .property--3-ab-smaller {
    grid-row: 6 / span 1;
    grid-column: 1 / span 10;
  }
  .value--2-ab {
    grid-row: 7 / span 1;
    grid-column: 1 / span 10;
  }
  .value--3-ab-smaller {
    grid-row: 7 / span 1;
    grid-column: 1 / span 10;
  }
}
