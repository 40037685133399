/*
//margin-top
@margin-top-smaller: 1rem;
@margin-top-small: 2rem;
@margin-top-medium: 4rem;
@margin-top-big: 6rem;
@margin-top-large: 8rem;
@margin-top-larger: 10rem;

//margin-bottom
@margin-bottom-smaller: 1rem;
@margin-bottom-small: 2rem;
@margin-bottom-medium: 4rem;
@margin-bottom-big: 6rem;
@margin-bottom-large: 8rem;
@margin-bottom-larger: 10rem;
*/
.navbar-fixed-props {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  font-family: "Arial";
}
.image-clickable-side {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 101;
}
.image-slider-button {
  position: fixed;
  top: 50%;
  background-color: transparent;
  font-family: 'Fredoka One';
  color: #fff;
  font-weight: 600;
  font-size: 3.8rem;
  cursor: pointer;
  border: solid white;
  border-width: 0 8px 8px 0;
  border-radius: 10%;
  display: inline-block;
  padding: 2.5rem;
}
@media only screen and (max-width: 28.125rem) {
  .image-slider-button {
    top: 48%;
    padding: 1.8rem;
    border-width: 0 5px 5px 0;
  }
}
.pos-abs {
  position: absolute;
  top: 0;
  left: 0;
}
.bg-image {
  width: 100%;
  height: auto;
  display: block;
  background-size: cover;
}
.button-without-outline:focus {
  outline: none;
}
.button-push-animation:active {
  transform: translateY(2px);
}
.button-hover-animation:hover {
  opacity: 0.6;
}
.event-page {
  position: relative;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 6rem;
  background-color: whitesmoke;
}
.event-page .header-section {
  position: relative;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  background-size: cover;
  overflow: hidden;
  color: #ffffff;
}
.event-page .header-section__header-image {
  height: auto;
  width: 100%;
}
.event-page .header-section__bg-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.event-page .header-section__back-to-events-button {
  position: absolute;
  bottom: 4rem;
  right: 8%;
  text-decoration: none;
  background-color: transparent;
  padding: 0.5rem 3rem;
  border: 2px white solid;
  font-size: 1.8rem;
  color: #ffffff;
  font-weight: 600;
  transition: all 0.25s;
  cursor: pointer;
}
.event-page .header-section__back-to-events-button:focus {
  outline: none;
}
.event-page .header-section__back-to-events-button:active {
  transform: translateY(2px);
}
.event-page .header-section__back-to-events-button:hover {
  opacity: 0.6;
}
.event-page .event-data {
  display: block;
  max-width: 90rem;
  margin: 4rem auto 0 auto;
  background-color: white;
  padding: 6rem 5rem;
}
.event-page .event-data__title {
  font-size: 2.5rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
}
.event-page .event-data__published-at {
  width: 100%;
  text-align: right;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #aaa;
}
.event-page .event-data__content {
  width: 100%;
}
.event-page .event-data__content p {
  margin-bottom: 1rem;
}
.event-page .event-data__content img {
  max-width: 100%;
  height: auto;
}
.event-page .event-data__content h1,
.event-page .event-data__content h2,
.event-page .event-data__content h3,
.event-page .event-data__content h4,
.event-page .event-data__content h5,
.event-page .event-data__content h6 {
  margin: 1.5rem 0;
}
.event-page .event-data__content ul {
  margin-left: 2rem;
}
.event-page .event-data__content ul li {
  margin-left: 1rem;
  text-transform: capitalize;
}
.event-page .more-events-section {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #e3e3e3;
  padding: 3rem 0;
}
.event-page .title-of-more-events {
  width: 62%;
  margin: 3rem auto 5rem auto;
  font-size: 2.8rem;
}
@media only screen and (max-width: 75rem) {
  .event-page .title-of-more-events {
    width: 76%;
  }
  .event-page .header-section__back-to-events-button {
    right: 5%;
  }
}
@media only screen and (max-width: 56.25rem) {
  .event-page .header-section {
    height: 28vw;
  }
  .event-page .header-section__back-to-events-button {
    right: 2%;
  }
  .event-page .title-of-more-events {
    width: 58%;
  }
}
@media only screen and (max-width: 37.5rem) {
  .event-page .header-section__back-to-events-button {
    bottom: 3rem;
    right: 10%;
    font-size: 1.6rem;
  }
  .event-page .title-of-more-events {
    width: 74%;
    font-size: 2.4rem;
  }
}
@media only screen and (max-width: 28.125rem) {
  .event-page .header-section {
    height: 40vw;
  }
  .event-page .header-section__back-to-events-button {
    bottom: 2rem;
    right: 10%;
    padding: 0.5rem 2rem;
    border: 1px white solid;
    font-size: 1.6rem;
  }
}
